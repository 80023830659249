

































































import { HelperTaxRegNo, InputTaxRegNo } from "@/components/InputTaxRegNo";
import { SelectTaxInvoiceCode } from "@/components/TaxInvoice";
import { useArPrepayment, useDate } from "@/hooks";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { FormValue, State } from "@/store/aRPrepayment.store";
import { checkTaxNumber } from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";

@Component({
  components: {
    SelectTaxInvoiceCode,
    InputTaxRegNo,
    HelperTaxRegNo,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.aRPrepaymentStore,
    }),
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({
      setForm: "aRPrepaymentStore/setForm",
    }),
  },
})
export default class FormTax extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  @Ref("formModel")
  formModel!: FormModel;

  store!: State;
  setForm!: (payload: Partial<FormValue>) => void;

  formRules = {
    customerTaxType: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    taxInvoiceDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    taxRegistrationNumber: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
      { min: 15, message: this.$t("lbl_validation_min_length_x", { x: 15 }) },
      {
        validator: checkTaxNumber,
      },
    ],
  };

  setTaxInvoiceNumber(): void {
    const { toAsOfDate } = useDate();
    const { findTaxNumber } = useArPrepayment();
    const { customerTaxType, invoiceDate } = this.store.form;

    this.setForm({ taxInvoiceNumber: "" });

    if (!customerTaxType || !invoiceDate) return;

    findTaxNumber(customerTaxType, toAsOfDate(invoiceDate)).then(res => {
      this.setForm({ taxInvoiceNumber: res });
    });
  }

  onChangeTaxInvCode(): void {
    this.setTaxInvoiceNumber();
  }
}
