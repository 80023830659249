










import {
  formatTaxNumber,
  formatTaxNumbertoNumber,
} from "@/validator/globalvalidator";
import Vue from "vue";

export default Vue.extend({
  name: "InputTaxRegNo",
  props: {
    value: {
      required: true,
      type: String,
      default: undefined,
    },
  },
  data: () => ({}),
  methods: {
    onBlur(e) {
      this.$emit("input", formatTaxNumber(e.target.value));
    },
    onFocus(e) {
      this.$emit("input", formatTaxNumbertoNumber(e.target.value));
    },
    onChange(e): void {
      this.$emit("input", e.target.value);
      this.$emit("change", e.target.value);
    },
  },
});
