


























import { debounceProcess } from "@/helpers/debounce";
import { useArPrepayment } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { InvoicePrepaymentListResponseDto } from "@interface/ar-prepayment";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectArPrepayment extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<InvoicePrepaymentListResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    this.fetchOptions();
  }

  fetchOptions(
    params: RequestQueryParamsModel = new RequestQueryParams()
  ): void {
    const { findAll, toOptions } = useArPrepayment();
    this.loading = true;
    findAll(params)
      .then(({ data }) => {
        this.options = toOptions(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { findAllBy } = useArPrepayment();
    const { search } = findAllBy({
      invoiceNumber: val,
    });
    const params = new RequestQueryParams();
    params.search = search;

    this.fetchOptions(params);
  }

  findOption(
    value: string
  ): Option<InvoicePrepaymentListResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }
}
