var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-input", {
    attrs: { value: _vm.value, "allow-clear": "" },
    on: { blur: _vm.onBlur, focus: _vm.onFocus, change: _vm.onChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }