



























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useTaxInvoice } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { TaxInvoiceCodeListDto } from "@interface/tax-invoice-code";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectTaxInvoiceCode extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<TaxInvoiceCodeListDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = new RequestQueryParams(this.buildQuery());
    this.fetchOptions(params);
  }

  fetchOptions(params: RequestQueryParams): void {
    const { findAll, toOptions } = useTaxInvoice();
    this.loading = true;
    findAll(params)
      .then(res => {
        this.options = toOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { findAllBy } = useTaxInvoice();
    const { search } = findAllBy({ code: val, desc: val });
    const params = new RequestQueryParams();
    const query: string[] = [this.buildQuery()];
    if (search) {
      query.push(search);
    }

    params.search = query.join(new SearchBuilder().AND);
    this.fetchOptions(params);
  }

  findOption(value: string): Option<TaxInvoiceCodeListDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  /**
   * @description build default query
   */
  buildQuery(): string {
    const builder = new SearchBuilder();
    return builder.push(["revisionCode", "false"]).build();
  }
}
