


























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useTax } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { AccountingTaxResponseDto } from "@interface/master-tax";
import { Component, Prop, Vue } from "vue-property-decorator";

const DEFAULT_QUERY = "taxType~VAT_OUT_AND_rate>0";

@Component
export default class SelectTaxCode extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<AccountingTaxResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = this.buildParams();
    this.fetchOptions(params);
  }

  fetchOptions(
    params: RequestQueryParamsModel = new RequestQueryParams()
  ): void {
    const { findCollections, toOptions } = useTax();
    this.loading = true;
    findCollections(params)
      .then(({ data }) => {
        this.options = toOptions(data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useTax();
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    const queries: string[] = [];

    const { search } = this.buildParams();

    queries.push(search || "");

    if (val) queries.push(filterBy({ code: val }));

    params.search = queries.join(builder.AND);

    this.fetchOptions(params);
  }

  findOption(value: string): Option<AccountingTaxResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  buildParams(): RequestQueryParamsModel {
    const params = new RequestQueryParams();

    params.search = DEFAULT_QUERY;

    return params;
  }
}
