


























import { State as ArPrepaymentStore } from "@/store/aRPrepayment.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      store: (st: any) => st.aRPrepaymentStore as ArPrepaymentStore,
    }),
  },
})
export default class DisplayTotal extends Vue {
  store!: ArPrepaymentStore;

  @Prop({ required: false, default: 0, type: Number })
  subTotal!: number;

  @Prop({ required: false, default: 0, type: Number })
  totalTax!: number;

  @Prop({ required: false, default: 0, type: Number })
  total!: number;
}
