






































import SelectTaxCalculation from "@/components/custom/select/SelectTaxCalculation.vue";
import { Option } from "@/models/class/option.class";
import { FormValue, State } from "@/store/aRPrepayment.store";
import {
  formatterNumber,
  reverseFormatNumber,
} from "@/validator/globalvalidator";
import { AccountingTaxResponseDto } from "@interface/master-tax";
import { FormModel } from "ant-design-vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import { mapActions, mapMutations, mapState } from "vuex";
import SelectTaxCode from "./SelectTaxCode.vue";

@Component({
  components: {
    SelectTaxCode,
    SelectTaxCalculation,
  },
  computed: {
    ...mapState({
      store: (st: any) => st.aRPrepaymentStore,
      storeBaseDecimalPlace: (st: any) =>
        st.preferenceStore.baseDecimalPlace as number,
    }),
  },
  methods: {
    ...mapMutations({
      setForm: "aRPrepaymentStore/setForm",
    }),
    ...mapActions({
      calcPayment: "aRPrepaymentStore/calcPayment",
    }),
  },
})
export default class FormPayment extends Vue {
  reverseFormatNumber = reverseFormatNumber;
  formatterNumber = formatterNumber;

  @Ref("formModel")
  formModel!: FormModel;

  storeBaseDecimalPlace!: number;
  store!: State;
  setForm!: (payload: Partial<FormValue>) => void;
  calcPayment!: () => void;

  formRules = {
    amount: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    taxType: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
    taxCode: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  onChangeAmount(): void {
    this.calcPayment();
  }

  onChangeTaxCode(e?: Option<AccountingTaxResponseDto>): void {
    this.setForm({
      taxRate: e?.meta?.rate || 0,
      taxCode: e?.label || "",
    });
    this.calcPayment();
  }

  onChangeTaxCalc(): void {
    this.calcPayment();
  }
}
