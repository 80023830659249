



























import { SearchBuilder } from "@/builder";
import { debounceProcess } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { AccountingAccountResponseDto } from "@interface/accounting-account";
import { RequestQueryParamsModel } from "@interface/http.interface";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class SelectReceivableAccount extends Vue {
  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: true, type: String, default: "" })
  currencyCode!: string;

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<AccountingAccountResponseDto>[] = [];
  loading = false;

  created(): void {
    this.onSearch = debounceProcess(this.onSearch);

    const params = this.buildParams();
    this.fetchOptions(params);
  }

  @Watch("currencyCode")
  onChangeCurrencyCode(): void {
    this.fetchOptions(this.buildParams());
  }

  fetchOptions(params: RequestQueryParamsModel): void {
    const { findAllChildAccount, toOptions } = useCoa();
    this.loading = true;
    findAllChildAccount(params)
      .then(res => {
        this.options = toOptions(res.data);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
    this.$emit("update:meta", this.findOption(e));
  }

  onSearch(val = ""): void {
    const { filterBy } = useCoa();
    const { search } = this.buildParams();
    const queries: Array<string> = [];
    const findBy = filterBy({ code: val, description: val });
    const params = new RequestQueryParams();
    if (search) queries.push(search);
    if (findBy) queries.push(findBy);
    params.search = queries.join(new SearchBuilder().AND);

    this.fetchOptions(params);
  }

  findOption(value: string): Option<AccountingAccountResponseDto> | undefined {
    return this.options.find(e => value === e.value);
  }

  buildParams(): RequestQueryParamsModel {
    const params = new RequestQueryParams();
    const builder = new SearchBuilder();
    const queryParentAcc = builder
      .push(["parentAccount.code", "110201"])
      .and()
      .push(["currency.currencyCode", this.currencyCode])
      .build();

    params.search = queryParentAcc;

    return params;
  }
}
